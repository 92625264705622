.month-picker {
    z-index: 9999999;
}

.month-picker > .rmp-container .rmp-popup {
    position: static !important;
}
.month-picker > .rmp-container .rmp-popup .rmp-pad > div label,
.month-picker > .rmp-container .rmp-popup .rmp-pad li {
    font-size: 12px;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.disable {
    opacity: 0.5;
}
.month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
    top: 14px;
    height: 24px;
    width: 24px;
    background-color: #EFF2F7 !important;
    font-size: 0px;
    overflow: hidden;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next:before {
    transform: rotate(180deg);
    left: 2px;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i:before {
    content: " ";
    position: relative;
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0);
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    top: 8px;
}

.rmp-tab.rmp-btn.prev:hover,
.rmp-tab.rmp-btn.next:hover {
    background-color: #E1E7F0 !important;
}

.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover,
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
    background-color: #5285CC !important;
    color: #fff;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad li.range {
    border-radius: 0;
}

.rmp-overlay {
    pointer-events: none;
    display: none;
}
.month-picker > .rmp-container {
    position: static;
}

@media screen and (min-width: 768px) {

    .month-picker > .rmp-container .rmp-popup.range {
        width: 470px;
        padding: 10px;
    }

    .month-picker > .rmp-container .rmp-popup .rmp-pad {
        width: 49%;
    }

    .month-picker > .rmp-container .rmp-popup .rmp-pad li {
        line-height: 32px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
        background-color: #fff;
    }

    .month-picker > .rmp-container .rmp-popup.light {
        border-left: none;
        border-right: none;
        border-top: solid 1px #eff2f7;
        border-bottom: solid 1px #eff2f7;
        box-shadow: none;
    }

    .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
        background-color: #5285CC;
        color: #fff;
    }
}

@font-face {
	font-family: 'Arial';
	src: url('arial-italic-mt.eot');
	src: local('Arial Italic'), local('Arial-ItalicMT'),
		url('arial-italic-mt.eot?#iefix') format('embedded-opentype'),
		url('arial-italic-mt.woff') format('woff'),
		url('arial-italic-mt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Arial';
	src: url('arial-bold-italic-mt.eot');
	src: local('Arial Bold Italic'), local('Arial-BoldItalicMT'),
		url('arial-bold-italic-mt.eot?#iefix') format('embedded-opentype'),
		url('arial-bold-italic-mt.woff') format('woff'),
		url('arial-bold-italic-mt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Arial';
	src: url('arial-mt.eot');
	src: local('Arial'), local('ArialMT'),
		url('arial-mt.eot?#iefix') format('embedded-opentype'),
		url('arial-mt.woff') format('woff'),
		url('arial-mt.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Arial';
	src: url('arial-bold-mt.eot');
	src: local('Arial Bold'), local('Arial-BoldMT'),
		url('arial-bold-mt.eot?#iefix') format('embedded-opentype'),
		url('arial-bold-mt.woff') format('woff'),
		url('arial-bold-mt.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Arial';
	src: url('arial-black.eot');
	src: local('Arial Black'), local('Arial-Black'),
		url('arial-black.eot?#iefix') format('embedded-opentype'),
		url('arial-black.woff') format('woff'),
		url('arial-black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
